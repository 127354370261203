<template>
  <div>
    <b-row>
      <b-col
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>สถิติ</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Updated 1 mins ago
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                xl="3"
                sm="6"
                class=""
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-success"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ numberWithCommas(dataPaymentTransaction.wallet.balance || 0) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ยอดเงินใช้ได้
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                xl="3"
                sm="6"
                class=""
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-danger"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ numberWithCommas(dataPaymentTransaction.wallet.payoutFreeze.amount || 0) }} | {{ numberWithCommas(dataPaymentTransaction.wallet.payoutFreeze.fee || 0) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ยอดถอนเงินคงค้าง | ค่าธรรมเนียมคงค้าง
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
            <br>
            <hr>
            <div>ยอดรายการฝาก</div>
            <b-row>
              <b-col
                xl="3"
                sm="6"
                class=""
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-success"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ numberWithCommas(dataPaymentTransaction.depositBalance.amount || 0) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ยอดฝาก (ไม่หักค่าธรรมเนียม)
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                xl="3"
                sm="6"
                class=""
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-success"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ numberWithCommas(dataPaymentTransaction.depositBalance.fee || 0) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ค่าธรรมเนียม
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
            <br>
            <hr>
            <div>ยอดรายการถอน</div>
            <b-row>
              <b-col
                xl="3"
                sm="6"
                class=""
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-danger"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ numberWithCommas(dataPaymentTransaction.withdrawBalance.amount || 0) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ยอดถอน (ไม่หักค่าธรรมเนียม)
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                xl="3"
                sm="6"
                class=""
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-danger"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ numberWithCommas(dataPaymentTransaction.withdrawBalance.fee || 0) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ค่าธรรมเนียม
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card
          title="รายการเพย์เม้น"
        >
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="ยูส"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    v-model="tableQuery.userId"
                    placeholder="ยูส"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="ชนิดรายการ"
                  label-for="mc-city"
                >
                  <b-row>
                    <b-col md="6">
                      <v-select
                        v-model="tableQuery.txtype"
                        label="text"
                        :options="txtypeOptions"
                        :reduce="item => item.value"
                      >
                        <template #option="{ text, value }">
                          <b-avatar
                            class="mb-1"
                            :variant="`light-${{'WITHDRAW':'danger', 'DEPOSIT':'success'}[value]}`"
                            size="32"
                          >
                            <feather-icon
                              :icon="{'WITHDRAW':'TrendingDownIcon', 'DEPOSIT':'TrendingUpIcon'}[value]"
                              size="16"
                            />
                          </b-avatar>
                          <span> {{ text }}</span>
                        </template>
                      </v-select>
                    </b-col>
                    <b-col md="6">
                      <b-form-input
                        id="mc-city2"
                        v-model="tableQuery.amount"
                        placeholder="จำนวนเงิน"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="สถานะรายการ"
                  label-for="mc-last-name"
                >
                  <v-select
                    v-model="tableQuery.status"
                    label="text"
                    :options="statusOptions"
                    :reduce="item => item.value"
                  >
                    <template #option="{ text, icon, color }">
                      <b-avatar
                        class="mb-1"
                        :variant="`light-${color}`"
                        size="32"
                      >
                        <feather-icon
                          :icon="icon"
                          size="16"
                        />
                      </b-avatar>
                      <span> {{ text }}</span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="วันที่รายการ"
                  label-for="mc-country"
                >
                  <b-row>
                    <b-col md="6">
                      <flat-pickr
                        v-model="tableQuery.starttime"
                        class="form-control"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:S', time_24hr: true, minuteIncrement:1 }"
                      />
                    </b-col>
                    <b-col md="6">
                      <flat-pickr
                        v-model="tableQuery.endtime"
                        class="form-control"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:S', time_24hr: true, minuteIncrement:1 }"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="searchTable"
                >
                  ค้นหา
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="clearSearch"
                >
                  เคลียร์
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <br>
          <b-table
            ref="paymentTable"
            striped
            hover
            responsive
            class="position-relative"
            :per-page="tableQuery.pageSize"
            :current-page="tableQuery.page"
            :items="fetchPaymentTransaction"
            :total-rows="totalRows"
            :fields="transactionTableFields"
          >
            <template #cell(user)="data">
              <div v-if="data.item.user">
                <b-badge
                  v-if="data.item.user.level != 'MEMBER'"
                  :variant="'light-danger'"
                >
                  {{ data.item.user.level }}
                </b-badge>
                {{ data.item.user.phoneno }}<br>
                {{ data.item.user.username }}
              </div>
            </template>
            <template #cell(status)="data">
              <b-avatar
                class="mb-1"
                :variant="`light-${['warning', 'success', 'danger'][data.item.status]}`"
                size="32"
              >
                <feather-icon
                  :icon="['ClockIcon', 'CheckIcon', 'XOctagonIcon'][data.item.status]"
                  size="16"
                />
              </b-avatar>
            </template>
            <template #cell(txtype)="data">
              <b-avatar
                class="mb-1"
                :variant="`light-${{'WITHDRAW':'danger', 'DEPOSIT':'success'}[data.item.txtype]}`"
                size="32"
              >
                <feather-icon
                  :icon="{'WITHDRAW':'TrendingDownIcon', 'DEPOSIT':'TrendingUpIcon'}[data.item.txtype]"
                  size="16"
                />
              </b-avatar>
            </template>
            <template #cell(notify)="data">
              <b-avatar
                class="mb-1"
                :variant="`light-${['warning','success'][data.item.notify]}`"
                size="32"
              >
                <feather-icon
                  :icon="['AlertCircleIcon' ,'CheckIcon'][data.item.notify]"
                  size="16"
                />
              </b-avatar>
            </template>
            <template #cell(detail)="data">
              <b-badge :variant="{0:'light-danger', 1:'light-success'}[data.item.success || 0]">
                {{ {0:'ไม่ได้เติม', 1:'เติมสำเร็จ'}[data.item.success || 0 ] }}
              </b-badge>
              <b-badge :variant="'light-warning'">
                {{ data.item.src || 'MANUAL' }}
              </b-badge>
              <b-badge :variant="'light-error'">
                เลขที่บิล: {{ `${(data.item.order_id || '').slice(-5)}-${(data.item.order_id || '').substring(0, 12)}` }}
              </b-badge><br>
              {{ `${data.item.detail}` }}
              <span v-if="data.item.accno"><br>{{ `${data.item.bank} ${data.item.accno}` }}</span>
              <span
                v-if="data.item.note"
                style="color:red;"
              ><br>({{ data.item.note }})</span>
            </template>
            <template #cell(bank_acc_no)="data">
              <div v-if="data.item.user">
                <img
                  :src="getBankImage(data.item.user.bank_code)"
                  style="width: 24px;"
                > {{ `${data.item.user.bank_name}` }}<br>{{ `${data.item.user.bank_acc_no}` }}
              </div>
            </template>
            <template #cell(amount)="data">
              <div style="text-align: right;">
                {{ `${numberWithCommas(data.item.amount)}` }}
              </div>
            </template>
            <template #cell(fee)="data">
              <div style="text-align: right;">
                {{ `${numberWithCommas(data.item.fee)}` }}
              </div>
            </template>
            <template #cell(note)="data">
              <div
                v-if="data.item.slip_url"
                style="text-align: right;"
              >
                <span
                  title="show"
                  @click="viewSlip(data.item)"
                >หลักฐาน</span>
              </div>
              {{ data.item.note }}
            </template>
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="tableQuery.pageSize"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="tableQuery.page"
                :total-rows="totalRows"
                :per-page="tableQuery.pageSize"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalSlip.show"
      title=""
      header-bg-variant="dark"
      header-text-variant="dark"
      body-bg-variant="dark"
      body-text-variant="dark"
      footer-bg-variant="dark"
      footer-text-variant="dark"
      hide-footer
      hide-header
      centered
    >
      <img
        v-if="modalSlip.slip_url"
        :src="modalSlip.slip_url"
        style="width: 400px;"
      >
    </b-modal>
    <b-modal
      id="modal-mark-complete"
      v-model="modalMarkComplete.show"
      cancel-variant="outline-secondary"
      ok-title="เรียบร้อย"
      cancel-title="ยกเลิก"
      centered
      title="ตั้งเป็นรายการเรียบร้อย"
      @ok="confirmMarkComplete"
    >
      <b-form>
        <b-form-group>
          <label for="reject-reason">เหตุผล:</label>
          <b-form-input
            id="seize-reason"
            v-model="modalMarkComplete.reason"
            type="text"
            placeholder="เหตุผล"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-match-user"
      v-model="modalMatchUser.show"
      cancel-variant="outline-secondary"
      ok-title="ยันยันการจับคู่"
      cancel-title="ยกเลิก"
      centered
      title="จับคู่ยอดฝาก"
      @ok="confirmMatchUser"
      @hide="hideMatchUser"
    >
      <b-form>
        <div style="margin-bottom: 10px;">
          {{ formatDate(modalMatchUser.meta.slip_datetime) }} {{ modalMatchUser.meta.detail }}
          <br>{{ modalMatchUser.meta.bank }} {{ modalMatchUser.meta.accno }}
          <br>{{ modalMatchUser.meta.amount }} บาท
          <br>{{ ' ' }}
        </div>
        <!-- <b-form-group> -->
        <b-row>
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend>
                <b-button variant="outline-primary">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-prepend>
              <cleave
                id="member-bank-acc"
                v-model="modalMatchUser.userInput"
                class="form-control"
                :raw="false"
                :options="{
                  numericOnly: true,
                  numeralThousandsGroupStyle: 'none',
                  numeral: true,
                  stripLeadingZeroes: false,
                }"
                placeholder="เบอร์โทร"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="searchUserForMatching('phoneno')"
                >
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend>
                <b-button variant="outline-primary">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                id="seize-reason"
                v-model="modalMatchUser.username"
                type="text"
                placeholder="ยูสเซอร์"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="searchUserForMatching('username')"
                >
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <!-- </b-form-group> -->
        <div
          v-if="modalMatchUser.user.id"
          style="margin: 10px 0px; text-align: center; border: 1px solid #fff; padding: 5px; border-radius: 10px"
        >
          {{ modalMatchUser.user.phoneno }} / {{ modalMatchUser.user.username }}
          <br>{{ modalMatchUser.user.fname }} {{ modalMatchUser.user.lname }}
          <br>บัญชีฝากถอน : {{ modalMatchUser.user.bank_name }} {{ modalMatchUser.user.bank_acc_no }}
        </div>
        <b-form-group>
          <label for="reject-reason">เหตุผล:</label>
          <b-form-input
            id="seize-reason"
            v-model="modalMatchUser.reason"
            type="text"
            placeholder="เหตุผล"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BInputGroupAppend, BInputGroupPrepend, BInputGroup, VBTooltip, BCardHeader, BCardTitle, BCardText, BMedia, BAvatar, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    vSelect,
    flatPickr,
    BInputGroupAppend,
    BInputGroupPrepend,
    Cleave,
    BCardHeader,
    BCardTitle,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      txtypeOptions: [
        { text: 'รายการฝาก', value: 'DEPOSIT' },
        { text: 'รายการถอน', value: 'WITHDRAW' },
      ],
      statusOptions: [
        {
          text: 'รอทำรายการ', value: 0, color: 'warning', icon: 'ClockIcon',
        },
        {
          text: 'สำเร็จ', value: 1, color: 'success', icon: 'CheckIcon',
        },
        {
          text: 'ยกเลิก/ไม่สำเร็จ', value: 2, color: 'danger', icon: 'AlertCircleIcon',
        },
      ],
      isBusy: false,
      refRecheck: null,
      modalSlip: { show: false },
      modalMarkComplete: {
        show: false,
        reason: '',
        meta: {},
      },
      modalMatchUser: {
        show: false,
        reason: '',
        meta: {},
        user: {
          phoneno: '',
          fname: '',
          lname: '',
          bank: '',
          bank_code: '',
          bank_acc_no: '',
        },
      },
      pageOptions: [50, 100, 200, 500, 1000],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        pageSize: 50,
        starttime: moment().format('YYYY-MM-DD 00:00:00'),
        endtime: moment().format('YYYY-MM-DD 23:59:59'),
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      transactionTableFields: [
        { key: 'created_at', label: 'วันที่', formatter: val => this.formatDate(val) },
        { key: 'success_at', label: 'วันที่รายการ', formatter: val => this.formatDate(val) },
        { key: 'status', label: 'สถานะ' },
        { key: 'user_id', label: 'ยูสลูกค้า' },
        { key: 'txtype', label: 'ชนิดรายการ' },
        { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val) },
        { key: 'fee', label: 'ค่าธรรมเนียม', formatter: val => this.numberWithCommas(val) },
        { key: 'txn_id', label: 'รหัสรายการ' },
        { key: 'ref_id', label: 'รหัสอ้างอิง' },
        { key: 'notify', label: 'แจ้งทำราย' },
      ],
      unsuccessTableFields: [
        { key: 'act_match', label: 'เติม' },
        { key: 'act_processed', label: 'เรียบร้อย' },
        { key: 'slip_datetime', label: 'วันที่', formatter: val => this.formatDate(val) },
        { key: 'detail', label: 'รายการ' },
        { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val) },
        { key: 'match', label: 'จับคู่' },
        { key: 'tx_acc', label: 'บัญชีฝาก' },
        { key: 'note', label: 'หมายเหตุ' },
      ],
      member_groups: JSON.parse(localStorage.getItem('mg') || '[]'),
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      dataPaymentTransaction: {
        wallet: {
          balance: null,
          payoutFreeze: {
            amount: null,
            fee: null,
          },
        },
        depositBalance: {
          amount: null,
          fee: null,
        },
        withdrawBalance: {
          amount: null,
          fee: null,
        },
        transactions: {
          meta: {},
          data: [],
        },
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  destroyed() {
    clearInterval(this.refRecheck)
    console.log('destroyed hook called')
  },
  mounted() {
    // Set the initial number of items
    const ctx = this
    // ctx.fetchIncompleteList()
    // ctx.fetchPaymentTransaction()
    // this.refRecheck = setInterval(() => {
    //   if (ctx.refRecheck == null)
    //     return
    //   if (ctx.$refs.paymentTable)
    //     ctx.$refs.paymentTable.refresh()
    //   ctx.fetchPaymentTransaction()
    // }, 45000)
    this.totalRows = this.dataPaymentTransaction.transactions.data.length

    // console.log('GET APP CODE', this.getAPPCode())

    // firebase.database().ref(this.getAPPCode()).child('richs_stats/deposit_today').on('value', snapshot => {
    //   const val = snapshot.val() || {}
    //   ctx.stats[0].title = `${(val.count_successful || 0)}`
    //   ctx.stats[1].title = `${this.numberWithCommas(val.sum_successful || 0)}`
    // })
    // firebase.database().ref(this.getAPPCode()).child('richs_stats/waiting_deposits').on('value', snapshot => {
    //   // ctx.fetchIncompleteList()
    // })
  },
  methods: {
    getHtmlImageSlip(url) {
      return `<img src="${url}">`
    },
    searchTable() {
      if (this.$refs.paymentTable)
        this.$refs.paymentTable.refresh()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        pageSize: 50,
        starttime: moment().format('YYYY-MM-DD 00:00:00'),
        endtime: moment().format('YYYY-MM-DD 23:59:59'),
      }
      if (this.$refs.paymentTable)
        this.$refs.paymentTable.refresh()
    },
    async matchUser(bankTransferData) {
      this.modalMatchUser.show = true
      this.modalMatchUser.meta = bankTransferData
    },
    async markComplete(bankTransferData) {
      this.modalMarkComplete.show = true
      this.modalMarkComplete.meta = bankTransferData
    },
    async confirmMarkComplete() {
      if (`${this.modalMarkComplete.reason}` === '') {
        return
      }
      const { data: { success } } = await this.$http.post('bot-deposits?action=complete', { data: { id: this.modalMarkComplete.meta.id }, reason: this.modalMarkComplete.reason })
      if (success) {
        this.modalMarkComplete = {
          show: false,
          reason: '',
          meta: {},
        }
        if (this.$refs.unsuccessTable)
          this.$refs.unsuccessTable.refresh()
        this.fetchIncompleteList()
      }
    },
    async searchUserForMatching(mode = 'phoneno') {
      if (`${this.modalMatchUser.userInput || ''}` === '' && (this.modalMatchUser.username || '') === '') {
        return
      }
      this.modalMatchUser.found = false
      const { data: { user } } = await this.$http.get(`users/user-info?with_deposits=N&user=${mode === 'phoneno' ? this.modalMatchUser.userInput : this.modalMatchUser.username}&searchBy=${mode}`)
      this.modalMatchUser.user = user
      this.modalMatchUser.username = (user || {}).username || ''
      this.modalMatchUser.found = user && user.id
    },
    async confirmMatchUser() {
      if (!this.modalMatchUser.found) {
        return
      }

      const { data: { success, data: bankTx } } = await this.$http.post('bot-deposits?action=match', { data: { id: this.modalMatchUser.meta.id }, reason: this.modalMatchUser.reason, username: this.modalMatchUser.user.username })
      if (success) {
        this.hideMatchUser()
        this.fetchIncompleteList()
      }
    },
    async hideMatchUser() {
      this.modalMatchUser = {
        show: false,
        reason: '',
        meta: {},
        found: false,
        user: {
          phoneno: '',
          fname: '',
          lname: '',
          bank: '',
          bank_code: '',
          bank_acc_no: '',
        },
      }
    },
    async fetchPaymentTransaction() {
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      const { data: _payments } = await this.$http.get(`huskypay-transactions${query !== '' ? (`?${query}`) : ''}`)
      this.totalRows = _payments.data.transactions.meta.total
      this.dataPaymentTransaction = _payments.data
      return _payments.data.transactions.data
    },
    viewSlip(data) {
      this.modalSlip.show = true
      this.modalSlip.slip_url = data.slip_url
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tooltip { top: 0; }
</style>
